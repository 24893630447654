import track from '@/helpers/track.js'

export default function (tracking) {
  const route = {
    name: document.querySelector('meta[name="route.name"]')?.content,
    path: document.querySelector('meta[name="route.path"]')?.content,
    uri: document.querySelector('meta[name="route.uri"]')?.content,
    parameters: JSON.parse(
      document.querySelector('meta[name="route.parameters"]')?.content || '{}'
    )
  }

  switch (route.name) {
    case 'web.multilingual.post':
    case 'web.post':
    case 'web.multilingual.route':
    case 'web.route':
      track({
        event: 'page_view',
        append: {
          page: route.name,
          ...route.parameters
        }
      })
      break
  }
}
