<template>
  <img :src="emoji" :alt="emoji" :class="classes" />
</template>

<script setup>
  // Libs
  import { computed } from 'vue'

  // Props
  const props = defineProps({
    emoji: {
      type: String,
      default: null
    },

    classes: {
      type: Array,
      default: []
    }
  })

  // Variables
  // Emojis
  import black_heart from '@/assets/emojis/black_heart.png'
  import blue_heart from '@/assets/emojis/blue_heart.png'
  import brown_heart from '@/assets/emojis/brown_heart.png'
  import green_heart from '@/assets/emojis/green_heart.png'
  import orange_heart from '@/assets/emojis/orange_heart.png'
  import pink_heart from '@/assets/emojis/pink_heart.png'
  import red_heart from '@/assets/emojis/red_heart.png'
  import white_heart from '@/assets/emojis/white_heart.png'
  import yellow_heart from '@/assets/emojis/yellow_heart.png'
  import fire from '@/assets/emojis/fire.png'
  import hand from '@/assets/emojis/hand.png'

  //
  const emoji_dictionary = {
    black_heart,
    blue_heart,
    brown_heart,
    green_heart,
    orange_heart,
    pink_heart,
    red_heart,
    white_heart,
    yellow_heart,
    fire,
    hand
  }

  // Computed variables
  const emoji = computed(() => {
    return emoji_dictionary[props.emoji] || ''
  })
</script>
