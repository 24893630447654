<template>
  <div class="flex flex-col gap-2">
    <input_component
      :settings="input_search"
      @update:value="
        ($event) => {
          search = $event
          get_records()
        }
      "
    />

    <transition name="fade">
      <div class="flex flex-wrap gap-2" v-if="symptoms.length > 0">
        <span
          class="badge badge-light cursor-pointer"
          v-for="symptom in symptoms.filter((x) => x.title !== search)"
          :key="symptom.id"
          @click="select_a_symptom(symptom)"
        >
          {{ symptom.title }}
        </span>
      </div>
    </transition>
  </div>

  <div class="flex flex-col gap-2" v-if="not_results_found">
    <alert_component>
      <template #message>
        {{
          gt(
            "It seems we didn't find exactly what you're looking for 😕, but don't worry, if you're still interested, just click here and we'll get to work creating it for you."
          )
        }}
        <a :href="url({ path: '/suggestions' })">
          {{ gt('Would you like us to create a route for you?') }}
        </a>
      </template>
    </alert_component>
  </div>

  <routes_component
    default_style="swiper"
    :show_switcher="true"
    :routes="routes"
    :max_elements="max_elements"
    :on_click="redirect_to_route_page"
  />

  <posts_component :posts="posts" :loading="search_posts_loading" />
</template>

<script setup>
  // Components
  import { defineAsyncComponent, onBeforeMount } from 'vue'
  import { useRouter } from 'vue-router'
  import { onMounted, ref, computed } from 'vue'
  import { debounce } from 'lodash-es'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  const posts_component = defineAsyncComponent(
    () => import('@/components/app/posts/index.vue')
  )

  const routes_component = defineAsyncComponent(
    () => import('@/components/app/routes/index.vue')
  )

  // Stores
  import UserStore from '@/stores/user.js'
  import TopNavigationStore from '@/stores/top_navigation'
  import BottomNavigationStore from '@/stores/bottom_navigation.js'

  // Helpers
  import scroll_to_element from '@/helpers/scroll_to_element'
  import url from '@/helpers/url'
  import get_locale from '@/helpers/get_locale'
  import track from '@/helpers/track.js'
  import is_native_framework from '@/helpers/is_native_framework.js'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Services
  import Route from '@/methods/for_routes'
  import Post from '@/methods/for_posts'
  import Symptom from '@/methods/for_symptoms'

  // Variables
  const User = UserStore()
  const TopNavigation = TopNavigationStore()
  const BottomNavigation = BottomNavigationStore()

  const { is_logged } = User
  const $router = is_native_framework() ? useRouter() : null
  const max_elements = ref(7)
  const search = ref('')
  const search_posts_loading = ref(true)
  const search_symptoms_loading = ref(true)
  const input_search = ref({
    label: gt('Search for a symptom you might have...'),
    placeholder: gt('Example: Fear...'),
    name: 'search',
    type: 'text',
    default: search.value,
    icon: {
      name: 'fi-rr-search'
    }
  })

  const routes = ref([])
  const posts = ref([])
  const symptoms = ref([])

  // Computed variables
  const not_results_found = computed(() => {
    return routes.value.length + posts.value.length === 0
  })

  // Methods
  const get_routes_by_search = debounce(async function (args = {}) {
    args.per_page = args.per_page || max_elements.value
    args.with_relations = ['createdBy']
    args.with_properties = ['images', 'stats']
    args.filters = {
      search: search.value,
      symptoms: search.value,
      tags: search.value,
      diffuse: 1,
      language: get_locale()
    }

    if (search.value.length > 3) {
      twq('event', 'tw-okgqx-owq4l', {
        search_string: search.value
      })

      fbq('track', 'Search', {
        search_string: search.value
      })

      track({
        event: 'user_made_a_route_search',
        append: {
          search: search.value,
          language: get_locale()
        }
      })
    }

    const { records } = await Route.get(args)
    routes.value = records || []

    if (search.value.length > 0 && routes.value.length > 0) {
      scroll_to_element('.component-routes', 500)
    }
  }, 700)

  const get_posts_by_search = debounce(async function (args = {}) {
    args.per_page = args.per_page || 5
    args.with_relations = ['createdBy']
    args.with_properties = ['images', 'stats']
    args.filters = {
      search: search.value,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Post.get(args)

    posts.value = records || []
    search_posts_loading.value = false
  }, 700)

  const get_symptoms_by_search = debounce(async function (args = {}) {
    search_symptoms_loading.value = true
    args.per_page = args.per_page || 3
    args.with_relations = []
    args.with_properties = []

    args.filters = {
      search: search.value,
      symptoms_with_routes: 1,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Symptom.get(args)
    symptoms.value = records || []
    search_symptoms_loading.value = false
  }, 700)

  const get_params_and_use_it = async function () {
    const params = new URLSearchParams(window.location.search)
    const keyword = params.get('keyword', null)

    if (keyword && keyword.length > 0) {
      search.value = keyword
      input_search.value.default = keyword
      input_search.value.value = keyword
    }
  }

  const select_a_symptom = function (symptom) {
    search.value = symptom.title
    input_search.value.default = symptom.title
    input_search.value.value = symptom.title
  }

  const get_records = async function () {
    await get_routes_by_search()
    await get_posts_by_search()
    await get_symptoms_by_search()
  }

  const redirect_to_route_page = function (route) {
    track({
      event: 'user_had_good_route_search_results',
      append: {
        search: search.value,
        language: get_locale(),
        interesting_route: route.id
      }
    })

    if ($router) {
      $router.push(`/route/${route.slug}`)
    } else {
      window.location.href = url({ path: `/route/${route.slug}` })
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('Routes'),
      go_back: is_logged ? '/journey' : '/'
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'continue'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    set_title_and_navigation()
  })

  onMounted(async () => {
    await get_params_and_use_it()
    await get_records()
  })
</script>
