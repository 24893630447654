<template>
  <title_component :size="'large'">
    <template #title> {{ gt('Appointment') }} </template>
    <template #subtitle>
      {{ gt('Confirm or cancel an appointment') }}
    </template>
  </title_component>

  <alert_component v-if="loading" :loader="true">
    <template #title> {{ gt('Wait some seconds...') }} </template>
    <template #message>
      {{ gt('We are finding your appointments.') }}
    </template>
  </alert_component>

  <appointment_component
    v-if="!loading && appointment"
    :appointment="appointment"
    :on_confirm="on_confirm"
    :on_cancel="on_cancel"
  />

  <p class="text-slate-700">
    {{
      gt(
        'Confirm or cancel your appointment, it is important for us to know if we can count on you on the day of your scheduled appointment.'
      )
    }}
  </p>

  <alert_component v-if="!loading && !appointment">
    <template #title> {{ gt('Hmmm...') }} </template>
    <template #message>
      {{ gt('We could not find your appointment') }}
    </template>
  </alert_component>
</template>
<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, ref, onBeforeMount, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const appointment_component = defineAsyncComponent(
    () => import('@/components/app/appointment/index.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Services
  import Appointment from '@/methods/for_appointments'

  // Helpers
  import url from '@/helpers/url'
  import get_query_param from '@/helpers/get_query_param.js'
  import is_native_framework from '@/helpers/is_native_framework.js'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import AppStore from '@/stores/app'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const ALERT_LEVELS = ENV.enums.AlertLevels

  const User = UserStore()
  const App = AppStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const $router = is_native_framework() ? useRouter() : null
  const { is_logged } = User
  const appointment_id = ref(get_query_param('appointment_id'))
  const user_id = ref(get_query_param('user_id'))
  const authorization = ref(get_query_param('authorization'))
  const appointment = ref(null)
  const loading = ref(true)

  // Methods
  const get_appointment_by_id = async (args = {}) => {
    if (!is_logged) {
      return
    }

    loading.value = true

    args.with_relations = ['host', 'guest']
    args.with_properties = ['status', 'meeting_channel']
    args.order_by = 'start_at|desc'

    args.filters = {
      id: appointment_id.value
    }

    const { records } = await Appointment.appointments(args)

    appointment.value = records.length > 0 ? records[0] : null
    loading.value = false
  }

  const on_confirm = () => {
    App.notification({
      title: gt('Appointment confirmed'),
      message: gt(
        'Your appointment has been confirmed, you will be redirected.'
      ),
      level: ALERT_LEVELS.SUCCESS
    })
  }

  const on_cancel = () => {
    App.notification({
      title: gt('Appointment canceled'),
      message: gt(
        'Your appointment has been canceled, you will be redirected.'
      ),
      level: ALERT_LEVELS.LIGHT
    })
  }

  function redirect_if_is_not_logged() {
    if (!is_logged) {
      App.notification({
        title: gt('Unauthorized'),
        message: gt(
          'You need to be logged in to confirm an appointment, you will be redirected to the login page in some seconds.'
        )
      })

      setTimeout(() => {
        const redirect_to = url({
          with_origin: !is_native_framework(),
          path: '/login',
          params: {
            redirect_to: `/appointment/?appointment_id=${appointment_id.value}`,
            user_id: user_id.value,
            authorization: authorization.value
          }
        })

        if ($router) {
          $router.push(redirect_to)
        } else {
          window.location.href = redirect_to
        }
      }, 3000)
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('Appointment'),
      go_back: is_logged ? '/journey' : '/'
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'continue'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    get_appointment_by_id()
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
