export default function (selector, duration = 500) {
  const element = document.querySelector(selector)

  if (!element) {
    return
  }

  const start = window.scrollY
  const target_position = element.getBoundingClientRect().top - 15
  const start_time = performance.now()

  function ease_out_quad(t) {
    return t * (2 - t)
  }

  function animate_scroll(current_time) {
    const time_elapsed = current_time - start_time
    const run =
      ease_out_quad(Math.min(time_elapsed / duration, 1)) * target_position +
      start
    window.scrollTo(0, run)

    if (time_elapsed < duration) {
      requestAnimationFrame(animate_scroll)
    }
  }

  requestAnimationFrame(animate_scroll)
}
