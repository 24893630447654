// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'
import DeleteWrapper from './helpers/wrappers/delete'

// Services
import PostService from '@/services/post'

export default {
  async get(args = {}) {
    const { success, posts, pagination } = await GetWrapper(
      new PostService().get,
      args
    )

    return {
      success,
      records: posts || [],
      pagination
    }
  },

  async store({ $form, append = {} }) {
    return StoreWrapper(new PostService().store, {
      $form,
      append
    })
  },

  async update_image_using_unsplash({ id }) {
    return StoreWrapper(new PostService().update_image_using_unsplash, {
      append: { id },
      silent: true
    })
  },

  async store_correlation({ $form, append = {} }) {
    return StoreWrapper(new PostService().store_correlation, {
      $form,
      append
    })
  },

  async update_post_like({ post_id }) {
    return StoreWrapper(new PostService().update_like, {
      silent: true,
      append: { post_id }
    })
  },

  async get_correlations(args = {}) {
    const { success, correlations } = await GetWrapper(
      new PostService().get_correlations,
      args
    )

    return {
      success,
      correlations
    }
  },

  async get_stats(args = {}) {
    const { success, post, liked_by_me } = await GetWrapper(
      new PostService().get_stats,
      args
    )

    return {
      success,
      post,
      liked_by_me
    }
  },

  async get_ai_content(args = {}) {
    const { success, response } = await GetWrapper(
      new PostService().get_ai_content,
      args
    )

    return {
      success,
      response
    }
  },

  async delete({ id }) {
    const { success, message } = await DeleteWrapper(new PostService().delete, {
      id
    })

    return {
      success,
      message
    }
  },

  async delete_correlation({ id }) {
    const { success, message } = await DeleteWrapper(
      new PostService().delete_correlation,
      { id }
    )

    return {
      success,
      message
    }
  },

  async categories(args = {}) {
    args.per_page = 100

    const { success, categories, pagination } = await GetWrapper(
      new PostService().categories,
      args
    )

    return {
      success,
      records: categories || [],
      pagination
    }
  },

  async delete_category({ id }) {
    const { success, message } = await DeleteWrapper(
      new PostService().delete_category,
      { id, append: { force: true } }
    )

    return {
      success,
      message
    }
  },

  async update_category({ $form, append = {} }) {
    return StoreWrapper(new PostService().update_category, {
      $form,
      append
    })
  }
}
