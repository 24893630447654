// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  appointments = async (body = {}) => {
    return await this.execute({
      name: 'appointment.my_appointments',

      method: 'get',
      target: '/v1/appointments',
      body
    })
  }

  events = async (body = {}) => {
    return await this.execute({
      name: 'appointment.events',

      method: 'get',
      target: '/v1/events',
      body
    })
  }

  spaces = async (body = {}) => {
    return await this.execute({
      name: 'appointment.spaces',

      method: 'get',
      target: '/v1/event/spaces',
      body
    })
  }

  store = async (body = {}) => {
    return await this.execute({
      name: 'appointment.store',

      method: 'post',
      target: '/v1/appointment',
      body
    })
  }

  confirm = async (body = {}) => {
    return await this.execute({
      name: 'appointment.confirm',

      method: 'post',
      target: '/v1/appointment/confirm',
      body
    })
  }

  cancel = async (body = {}) => {
    return await this.execute({
      name: 'appointment.cancel',

      method: 'post',
      target: '/v1/appointment/cancel',
      body
    })
  }

  notification = async (body = {}) => {
    return await this.execute({
      name: 'appointment.notification',

      method: 'get',
      target: '/v1/appointment/notification',
      body
    })
  }
}
