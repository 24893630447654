<template>
  <title_component>
    <template #title> {{ gt('Manage your subscription') }} </template>
    <template #subtitle>
      {{
        gt(
          'Manage your credits and subscriptions on the platform, here you will see your active subscriptions and transaction history as well as the possibility to cancel them.'
        )
      }}
    </template>
  </title_component>

  <template v-if="!is_web">
    <alert_component>
      <template #title>
        {{ gt('Hey!') }}
      </template>
      <template #message>
        {{ gt('Subscriptions are temporarily disabled from mobile devices.') }}
      </template>
    </alert_component>
  </template>

  <template v-else>
    <div class="flex gap-2" v-if="!subscriptions_is_loading">
      <div class="card flex flex-col p-4 !gap-0" v-if="credits > 0">
        <span class="text-slate-700">{{ gt('Credits') }}</span>
        <span class="text-lg text-slate-800 font-bold">{{ credits }}$</span>
      </div>
      <div class="card flex flex-col p-4 !gap-0" v-if="is_a_subscriber">
        <span class="text-slate-700">{{ gt('Active subscription') }}</span>
        <span class="text-lg text-slate-800 font-bold">
          {{ gt(my_monthly_active_subscription?.subscription?.title || '---') }}
        </span>
      </div>
    </div>

    <p class="text-slate-700 font-medium">
      {{ gt('Also you can...') }}
    </p>

    <div class="flex gap-2">
      <div class="flex flex-col gap-1">
        <button_component
          :classes="['btn', 'btn-dark']"
          :disabled="!is_a_subscriber"
          :loading="loading_request_subscriptions_cancellation"
          @click="cancel_all_subscriptions()"
        >
          <i class="fi fi-rr-time-delete"></i>
          {{ gt('Stop renewals') }}
        </button_component>
        <p class="text-slate-700 text-sm">
          {{
            gt(
              'We will cancel your active subscriptions, however your current period will remain valid, you will not receive any new charges in the future.'
            )
          }}
        </p>
      </div>

      <div class="flex flex-col gap-1">
        <button_component
          :classes="['btn', 'btn-light']"
          :disabled="credits === 0"
          @click="refund()"
          :loading="loading_request_refund"
        >
          <i class="fi fi-rr-truck-arrow-left"></i>
          {{ gt('Refund') }}
        </button_component>
        <p class="text-slate-700 text-sm">
          {{
            gt(
              'We will send a message to the administration to process the credits refund process, we will write you by mail and text message.'
            )
          }}
        </p>
      </div>
    </div>
  </template>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'
  import { onBeforeMount, onMounted, ref, computed } from 'vue'
  import { useRouter } from 'vue-router'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Services
  import Subscriptions from '@/methods/for_subscriptions.js'
  import Users from '@/methods/for_users.js'

  // Helpers
  import is_native_framework from '@/helpers/is_native_framework.js'
  import get_device_environment from '@/helpers/get_device_environment.js'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import TopNavigationStore from '@/stores/top_navigation'
  import AppStore from '@/stores/app'
  import BottomNavigationStore from '@/stores/bottom_navigation.js'

  // Variables
  const User = UserStore()
  const TopNavigation = TopNavigationStore()
  const BottomNavigation = BottomNavigationStore()
  const App = AppStore()

  const $router = is_native_framework() ? useRouter() : null
  const { user, is_logged } = User
  const subscriptions_is_loading = ref(null)
  const my_monthly_active_subscription = ref(null)
  const loading_request_refund = ref(false)
  const loading_request_subscriptions_cancellation = ref(false)
  const { is_web } = get_device_environment()

  // Computed variables
  const is_a_subscriber = computed(() => user.subscriber)
  const credits = computed(() => user.credits)

  // Methods
  const get_subscriptions = async function () {
    subscriptions_is_loading.value = true

    const { subscriptions } = await Subscriptions.get_subscriptions()

    subscriptions.value = subscriptions
    subscriptions_is_loading.value = false

    my_monthly_active_subscription.value = subscriptions.find(
      (subscription) => subscription.end_at !== null
    )
  }

  const cancel_all_subscriptions = async function () {
    loading_request_subscriptions_cancellation.value = true

    const { success, message } = await Subscriptions.cancel_all_subscriptions()

    if (!success) {
      App.notification({
        title: gt('Error cancelling your subscription.'),
        message
      })
    } else {
      App.notification({
        title: gt('Subscription cancelled.'),
        message
      })
    }

    if (success) {
      await get_subscriptions()
    }

    loading_request_subscriptions_cancellation.value = false
  }

  const refund = async function () {
    loading_request_refund.value = true
    await Users.refund()
    loading_request_refund.value = false
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('My Subscription and Credits'),
      go_back: '/journey'
    })

    BottomNavigation.update({
      visible: true,
      active: 'journey'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    get_subscriptions()
  })
</script>
