<template>
  <form class="w-full flex flex-col gap-2" @submit.prevent="submit()">
    <input_component
      v-show="step === 0"
      :settings="form.inputs.email"
      @update:value="form.inputs.email.value = $event"
    />

    <input_component
      v-show="step === 1"
      :settings="form.inputs.usage_frequency"
      @update:value="form.inputs.usage_frequency.value = $event"
    />

    <input_component
      v-show="step === 2"
      :settings="form.inputs.problem_to_solve"
      @update:value="form.inputs.problem_to_solve.value = $event"
    />

    <input_component
      v-show="step === 3"
      :settings="form.inputs.problem_solved"
      @update:value="form.inputs.problem_solved.value = $event"
    />

    <input_component
      v-show="step === 4"
      :settings="form.inputs.best_part"
      @update:value="form.inputs.best_part.value = $event"
    />

    <input_component
      v-show="step === 5"
      :settings="form.inputs.frustrations"
      @update:value="form.inputs.frustrations.value = $event"
    />

    <input_component
      v-show="step === 6"
      :settings="form.inputs.improvements"
      @update:value="form.inputs.improvements.value = $event"
    />

    <input_component
      v-show="step === 7"
      :settings="form.inputs.recommendation_score"
      @update:value="form.inputs.recommendation_score.value = $event"
    />

    <input_component
      v-show="step === 8"
      :settings="form.inputs.recommendation_reason"
      @update:value="form.inputs.recommendation_reason.value = $event"
    />

    <input_component
      v-show="step === 9"
      :settings="form.inputs.ideas_or_suggestions"
      @update:value="form.inputs.ideas_or_suggestions.value = $event"
    />

    <input_component
      v-show="step === 10"
      :settings="form.inputs.price_feedback"
      @update:value="form.inputs.price_feedback.value = $event"
    />

    <div class="flex w-full">
      <div class="progress">
        <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <div class="flex gap-2">
      <button_component
        :classes="['btn', 'btn-light', 'w-fit']"
        :disabled="is_first_step"
        @click="go_to(-1)"
      >
        <i class="fi fi-rr-angle-left"></i>
        {{ gt('Prev question') }}
      </button_component>

      <button_component
        :classes="['btn', 'btn-light', 'w-fit']"
        @click="go_to(1)"
        :disabled="is_last_step"
      >
        {{ gt('Next question') }}
        <i class="fi fi-rr-angle-right"></i>
      </button_component>
    </div>

    <div class="flex flex-col gap-2">
      <button_component
        :classes="['btn', 'btn-dark', 'w-fit']"
        :loading="form.is_loading"
        :submittable="true"
        :disabled="!is_last_step"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ gt('Send your feedback') }}
      </button_component>
    </div>
  </form>
</template>

<script setup>
  // Components
  import { defineAsyncComponent, ref, computed, onMounted } from 'vue'
  import Common from '@/methods/for_common_actions.js'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Stores
  import TopNavigationStore from '@/stores/top_navigation'

  // Helpers
  import get_form_state from '@/helpers/get_form_state'
  import url from '@/helpers/url.js'

  // Translations needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Variables
  const TopNavigation = TopNavigationStore()

  const query_params = new URLSearchParams(window.location.search)
  const email = query_params.get('email')
  const step = ref(email ? 1 : 0)

  const form = get_form_state('feedback', [
    {
      label: gt('Email'),
      description: gt('Your email address'),
      description_on_top: true,
      name: 'email',
      type: 'text',
      default: email,
      value: email
    },
    {
      label: gt('Usage frequency'),
      description: gt('How often do you use products like this?'),
      description_on_top: true,
      name: 'usage_frequency',
      type: 'select',
      options: [
        { id: null, value: gt('Select One') },
        { id: 1, value: gt('Daily') },
        { id: 2, value: gt('Weekly') },
        { id: 3, value: gt('Monthly') },
        { id: 4, value: gt('First time') }
      ],
      default: null,
      value: null
    },
    {
      label: gt('Problem'),
      description: gt('What issue were you trying to solve?'),
      description_on_top: true,
      name: 'problem_to_solve',
      type: 'textarea'
    },
    {
      label: gt('Solved problem'),
      description: gt('Did the product solve your issue? If yes, how?'),
      description_on_top: true,
      name: 'problem_solved',
      type: 'textarea'
    },
    {
      label: gt('Best part'),
      description: gt('What part of the product was most useful?'),
      description_on_top: true,
      name: 'best_part',
      type: 'textarea'
    },
    {
      label: gt('Frustrations'),
      description: gt('What did you find frustrating or unintuitive?'),
      description_on_top: true,
      name: 'frustrations',
      type: 'textarea'
    },
    {
      label: gt('Improvements'),
      description: gt('What would you change to make it better?'),
      description_on_top: true,
      name: 'improvements',
      type: 'textarea'
    },
    {
      label: gt('Recommendation'),
      description: gt(
        'On a scale of 1 to 10, how likely are you to recommend this product?'
      ),
      description_on_top: true,
      name: 'recommendation_score',
      type: 'select',
      options: [
        { id: null, value: gt('Select One') },
        ...Array.from({ length: 10 }, (_, i) => ({
          id: i + 1,
          value: `${i + 1}`
        }))
      ],
      default: null,
      value: null
    },
    {
      label: gt('Reason for score'),
      description: gt('Why did you give this score?'),
      description_on_top: true,
      name: 'recommendation_reason',
      type: 'textarea'
    },
    {
      label: gt('Suggestions'),
      description: gt('What would you add or change in the product?'),
      description_on_top: true,
      name: 'ideas_or_suggestions',
      type: 'textarea'
    },
    {
      label: gt('Price feedback'),
      description: gt('Do you think the product is worth its price? Why?'),
      description_on_top: true,
      name: 'price_feedback',
      type: 'textarea'
    }
  ])

  // Computed variables
  const progress = computed(() => {
    return Math.round(
      ((step.value + 1) * 100) / Object.keys(form.inputs).length
    )
  })

  const is_last_step = computed(() => {
    return step.value === Object.keys(form.inputs).length - 1
  })

  const is_first_step = computed(() => {
    return step.value === 0
  })

  // Methods
  const submit = async () => {
    form.is_loading = true

    const { success } = await Common.feedback({
      $form: form
    })

    if (success) {
      step.value = 0

      Object.keys(form.inputs).forEach((key) => {
        form.inputs[key].value = null
        form.inputs[key].default = null
      })
    }

    form.is_loading = false
  }

  const go_to = function (go_to_step) {
    step.value += go_to_step
  }

  // Lifecycle
  onMounted(() => {
    TopNavigation.update({
      loading: false,
      title: gt('Feedback'),
      go_back: url({ path: '/' })
    })
  })
</script>
