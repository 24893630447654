// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  get = async (body = {}) => {
    return await this.execute({
      name: 'subscriptions.get',

      method: 'get',
      target: '/v1/subscriptions',
      body
    })
  }

  get_payment_link = async (body = {}) => {
    return await this.execute({
      name: 'subscriptions.payment.link',

      method: 'get',
      target: '/v1/subscription/payment/link',
      body
    })
  }

  get_subscriptions = async (body = {}) => {
    return await this.execute({
      name: 'subscriptions.get_subscriptions',

      method: 'get',
      target: '/v1/my/subscriptions',
      body
    })
  }

  cancel_all_subscriptions = async (body = {}) => {
    return await this.execute({
      name: 'subscriptions.cancel_all_subscriptions',

      method: 'delete',
      target: '/v1/my/subscriptions',
      body
    })
  }
}
