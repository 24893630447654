<template>
  <template v-if="loading">
    <div class="flex flex-col gap-2">
      <div class="skeleton-block title"></div>
      <div class="skeleton-block subtitle"></div>

      <div class="flex gap-2">
        <div class="skeleton-block title"></div>
        <div class="skeleton-block title"></div>
        <div class="skeleton-block title"></div>
        <div class="skeleton-block title"></div>
      </div>
      <div class="skeleton-block title"></div>
      <div class="skeleton-block title"></div>
      <div class="skeleton-block title"></div>
      <div class="skeleton-block title"></div>
      <div class="skeleton-block title"></div>
    </div>
  </template>

  <template v-if="!loading && !ranking.length">
    <alert_component>
      <template #message>
        {{ gt('We did not find what you are looking for.') }}
      </template>
    </alert_component>
  </template>

  <template v-if="!loading && ranking.length > 0">
    <title_component size="large" class="mt-[100px]">
      <template #title>
        {{ gt('Leaderboard') }}
      </template>
      <template #subtitle>
        {{
          gt(
            'Look who is ahead of the curve and wait by betting on their mental health.'
          )
        }}
      </template>
    </title_component>

    <div class="flex flex-col gap-2">
      <div class="flex gap-2" v-for="user in ranking">
        <div
          class="text-xl flex items-center py-2 px-4 rounded-xl font-medium"
          :class="{
            'bg-slate-100': user.position % 2 === 0,
            'bg-slate-800': user.position === 1,
            'text-white': user.position === 1
          }"
        >
          <i
            class="fi fi-rr-arrow-small-up leading-none text-green-500"
            v-if="user.position_change > 0"
          ></i>
          <i
            class="fi fi-rr-arrow-small-down leading-none text-red-500"
            v-if="user.position_change < 0"
          ></i>

          {{ user.position }}
        </div>
        <div class="text-xl flex items-center gap-2 text-slate-700">
          {{ get_words(user.name, 1, '') }}
          <emoji_component
            emoji="fire"
            class="w-[18px] aspect-square"
            v-if="user.position === 1"
          />
        </div>
        <div class="text-xl flex grow items-center justify-end">
          <div class="flex gap-2 items-end">
            {{ user.points }}
            <span class="text-slate-700 text-sm">Pts</span>
          </div>
        </div>
      </div>
    </div>

    <p class="flex text-slate-700">
      {{
        gt(
          'The user ranking reflects how committed users are to their mental health and their use of the app, by completing routes, plans, sessions, and much more through our rewards system.'
        )
      }}
    </p>

    <p class="flex text-slate-700 text-sm">
      {{ gt('Updated every end of the day') }}
    </p>

    <div class="flex gap-2">
      <link_component :classes="['btn', 'btn-dark', 'w-fit']">
        <i class="fi fi-rr-award"></i>
        {{ gt('How do rewards work?') }}
      </link_component>
    </div>
  </template>
</template>

<script setup>
  // Components
  import { defineAsyncComponent, onBeforeMount, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const link_component = defineAsyncComponent(
    () => import('@/components/commons/link.vue')
  )

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Helpers
  import is_native_framework from '@/helpers/is_native_framework.js'
  import get_words from '@/helpers/get_words.js'

  // Services
  import RankingService from '@/methods/for_ranking.js'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const $router = is_native_framework() ? useRouter() : null
  const { is_logged } = User
  const loading = ref(false)
  const ranking = ref([])

  // Methods
  async function get_ranking() {
    loading.value = true
    const { success, records } = await RankingService.get()

    if (success) {
      ranking.value = records
    }

    loading.value = false
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('Ranking'),
      go_back: '/performance'
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'ranking'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    if (!is_logged) {
      return
    }

    get_ranking()
  })
</script>
