// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  get = async (body = {}) => {
    return await this.execute({
      name: 'ranking.get',

      method: 'get',
      target: '/v1/ranking',
      body
    })
  }
}
