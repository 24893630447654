<template>
  <form class="w-full flex flex-col gap-2" @submit.prevent="submit()">
    <input_component
      v-show="step === 0"
      :settings="form.inputs.email"
      @update:value="form.inputs.email.value = $event"
    />

    <input_component
      v-show="step === 1"
      :settings="form.inputs.reason"
      @update:value="form.inputs.reason.value = $event"
    />

    <input_component
      v-show="step === 2"
      :settings="form.inputs.description"
      @update:value="form.inputs.description.value = $event"
    />

    <div class="flex w-full">
      <div class="progress">
        <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>

    <div class="flex gap-2">
      <button_component
        :classes="['btn', 'btn-light', 'w-fit']"
        :disabled="is_first_step"
        @click="go_to(-1)"
      >
        <i class="fi fi-rr-angle-left"></i>
        {{ gt('Prev question') }}
      </button_component>

      <button_component
        :classes="['btn', 'btn-light', 'w-fit']"
        @click="go_to(1)"
        :disabled="is_last_step"
      >
        {{ gt('Next question') }}
        <i class="fi fi-rr-angle-right"></i>
      </button_component>
    </div>

    <div class="flex flex-col gap-2">
      <button_component
        :classes="['btn', 'btn-dark', 'w-fit']"
        :loading="form.is_loading"
        :submittable="true"
        :disabled="!is_last_step"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ gt('Send your message') }}
      </button_component>
    </div>
  </form>
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, ref, computed, onMounted } from 'vue'
  import Common from '@/methods/for_common_actions.js'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Stores
  import TopNavigationStore from '@/stores/top_navigation'
  import AppStore from '@/stores/app'

  // Helpers
  import get_form_state from '@/helpers/get_form_state'
  import url from '@/helpers/url.js'

  // Translations needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Variables
  const TopNavigation = TopNavigationStore()
  const App = AppStore()

  const query_params = new URLSearchParams(window.location.search)
  const email = query_params.get('email') || ''
  const step = ref(email ? 1 : 0)
  const USER_CONTACT_REASONS = ENV.enums.UserContactReasons

  const form = get_form_state('contact', [
    {
      label: gt('Email'),
      description: gt('Your email address'),
      description_on_top: true,
      name: 'email',
      type: 'text',
      default: email,
      value: email
    },
    {
      label: gt('Reason for contact'),
      description: gt('Select the reason for contacting us.'),
      description_on_top: true,
      name: 'reason',
      type: 'select',
      options: [
        {
          id: null,
          value: gt('Select One')
        },
        {
          id: USER_CONTACT_REASONS.BUG_REPORT,
          value: gt('Bug report')
        },
        {
          id: USER_CONTACT_REASONS.FEATURE_REQUEST,
          value: gt('Feature request')
        },
        {
          id: USER_CONTACT_REASONS.ACCOUNT_ISSUE,
          value: gt('Account issue')
        },
        {
          id: USER_CONTACT_REASONS.PAYMENT_ISSUE,
          value: gt('Payment issue')
        },
        {
          id: USER_CONTACT_REASONS.GENERAL_QUESTION,
          value: gt('General question')
        },
        {
          id: USER_CONTACT_REASONS.OTHER,
          value: gt('Other')
        }
      ],
      default: null,
      value: null
    },
    {
      label: gt('Message'),
      description: gt('Please provide details of your query or message.'),
      description_on_top: true,
      name: 'description',
      type: 'textarea',
      default: '',
      value: ''
    }
  ])

  // Computed variables
  const progress = computed(() => {
    return Math.round(
      ((step.value + 1) * 100) / Object.keys(form.inputs).length
    )
  })

  const is_last_step = computed(() => {
    return step.value === Object.keys(form.inputs).length - 1
  })

  const is_first_step = computed(() => {
    return step.value === 0
  })

  // Methods
  async function submit() {
    form.is_loading = true

    const { success, message } = await Common.contact({
      $form: form
    })

    if (success) {
      step.value = 0

      Object.keys(form.inputs).forEach((key) => {
        form.inputs[key].value = ''
        form.inputs[key].default = ''
      })
    }

    form.is_loading = false
  }

  const go_to = function (go_to_step) {
    step.value += go_to_step
  }

  // Lifecycle
  onMounted(() => {
    TopNavigation.update({
      loading: false,
      title: gt('Contact Us'),
      go_back: url({ path: '/' })
    })
  })
</script>
