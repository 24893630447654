// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get.js'

// Services
import SessionService from '@/services/session.js'

export default {
  async get_session(args = {}) {
    return GetWrapper(new SessionService().get_session, {
      ...args,
      silent: true
    })
  }
}
