<template>
  <div class="wrapper page-index">
    <div class="container !max-w-[720px] mt-[100px]">
      <div class="block">
        <span class="hey">
          <span class="left-side">
            <i class="fi fi-rr-bell-notification-social-media leading-none"></i>
          </span>

          <span class="message">
            {{ gt(random_copy['badged-message']) }}
          </span>
        </span>
      </div>

      <div class="block">
        <h1 class="title">
          {{ gt(random_copy['billboard-title']) }}
        </h1>
      </div>

      <div class="block">
        <p class="subtitle">
          {{ gt(random_copy['billboard-subtitle']) }}
        </p>
      </div>

      <div class="buttons">
        <a
          :title="gt('Get your plan today')"
          class="btn btn-lg btn-dark gap-2"
          :href="
            url({
              path: '/register',
              params: {
                utm_content: random_copy['id']
              }
            })
          "
        >
          <i class="fi fi-rr-journey text-xl leading-[0]"></i>
          {{ gt(random_copy['billboard-button']) }}
        </a>
      </div>
    </div>

    <div class="container !max-w-[1400px] mt-4 app-show-cases">
      <div class="flex gap-4 justify-center items-center">
        <swiper-container
          class="w-full"
          :slidesPerView="1"
          :spaceBetween="10"
          :breakpoints="breakpoints"
        >
          <swiper-slide>
            <div class="image-app bg-violet-50">
              <img
                :src="image_app_1"
                alt="App Screenshot"
                v-if="locale === 'es'"
              />
              <img :src="en_image_app_1" alt="App Screenshot" v-else />
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="image-app bg-blue-50">
              <img
                :src="image_app_2"
                alt="App Screenshot"
                v-if="locale === 'es'"
              />
              <img :src="en_image_app_2" alt="App Screenshot" v-else />
            </div>
          </swiper-slide>

          <swiper-slide>
            <div class="image-app bg-red-50">
              <img
                :src="image_app_3"
                alt="App Screenshot"
                v-if="locale === 'es'"
              />
              <img :src="en_image_app_3" alt="App Screenshot" v-else />
            </div>
          </swiper-slide>
        </swiper-container>
      </div>
    </div>

    <div class="container !max-w-[720px] mt-4 benefits">
      <div class="flex gap-2 flex-wrap justify-center items-center">
        <a :href="url({ path: '/routes' })" class="badge badge-transparent why">
          {{ gt('Our plans work for') }}
        </a>

        <a :href="url({ path: '/routes' })" class="badge badge-light benefit">
          <div class="icon">
            <i class="fi fi-rr-check"></i>
          </div>
          {{ gt('Insomnia') }}
        </a>

        <a :href="url({ path: '/routes' })" class="badge badge-light benefit">
          <div class="icon">
            <i class="fi fi-rr-check"></i>
          </div>
          {{ gt('Overthinking') }}
        </a>

        <a :href="url({ path: '/routes' })" class="badge badge-light benefit">
          <div class="icon">
            <i class="fi fi-rr-check"></i>
          </div>
          {{ gt('Sadness') }}
        </a>

        <a :href="url({ path: '/routes' })" class="badge badge-light benefit">
          <div class="icon">
            <i class="fi fi-rr-check"></i>
          </div>
          {{ gt('General anxiety') }}
        </a>
      </div>

      <div class="flex justify-center items-center">
        <a
          :title="gt('Get your free plan now!')"
          class="btn btn-dark w-fit gap-2"
          :href="
            url({
              path: '/register',
              params: {
                utm_content: random_copy['id']
              }
            })
          "
        >
          <i class="fi fi-rr-journey text-xl leading-[0]"></i>
          {{ gt('Get your free plan now!') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
  // Components
  import { onMounted, computed } from 'vue'

  // Helpers
  import url from '@/helpers/url'

  // Copies
  import copies from './assets/copies.json'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t, locale } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'

  // Variables
  const User = UserStore()

  const { is_logged } = User
  const query_params = new URLSearchParams(window.location.search)
  const utm_copy = query_params.get('utm_copy')

  // Images
  import image_app_1 from './assets/es01.png'
  import image_app_2 from './assets/es02.png'
  import image_app_3 from './assets/es03.png'

  import en_image_app_1 from './assets/en01.png'
  import en_image_app_2 from './assets/en02.png'
  import en_image_app_3 from './assets/en03.png'

  const breakpoints = {
    1280: {
      slidesPerView: 3
    },
    1024: {
      slidesPerView: 3
    },
    768: {
      slidesPerView: 2
    },
    640: {
      slidesPerView: 2
    },
    320: {
      slidesPerView: 1
    }
  }

  // Computed variables
  const random_copy = computed(() => {
    let specific_copies = copies.filter((copy) => copy['for'] === utm_copy)

    if (specific_copies.length === 0) {
      specific_copies = copies
    }

    return specific_copies[Math.floor(Math.random() * specific_copies.length)]
  })

  // Lifecycle
  onMounted(() => {
    if (is_logged) {
      window.location.href = url({ path: '/journey' })
    }
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
