<template>
  <div class="ad v1">
    <div class="ad-wrapper">
      <div class="title">
        {{ random_cta.title }}
      </div>
      <div class="description">
        {{ random_cta.subtitle }}
      </div>
    </div>

    <button_component
      :classes="['btn', 'btn-light', 'w-fit']"
      @click="go_to('/register')"
    >
      <i class="fi fi-rr-sign-in-alt"></i>
      {{ random_cta.button }}
    </button_component>
  </div>
</template>
<script setup>
  // Components
  import { defineAsyncComponent, computed } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  // Helpers
  import url from '@/helpers/url'
  import get_locale from '@/helpers/get_locale.js'

  // Variables
  import en_ctas from './assets/en_ctas.json'
  import es_ctas from './assets/es_ctas.json'

  const ctas = get_locale() === 'es' ? es_ctas : en_ctas

  // Computed variables
  const random_cta = computed(
    () => ctas[Math.floor(Math.random() * ctas.length)]
  )

  // Methods
  const go_to = (path) => {
    window.location.href = url({
      path
    })
  }
</script>
<style scoped lang="scss">
  @use './styles/ad.scss';
</style>
