<template>
  <main
    class="wrapper-for-app-pages !justify-end !grow bg-cover sm:bg-cover bg-center bg-no-repeat"
    :style="{
      backgroundImage: plan_image ? `url(${plan_image})` : ''
    }"
  >
    <div
      class="absolute w-full z-10 inset-0 bg-gradient-to-b from-transparent to-slate-800 pointer-events-none flex items-center justify-center"
      :class="{
        'bg-slate-700': plan_is_loading
      }"
    >
      <loader_component v-if="plan_is_loading" size="large" />
    </div>

    <div v-if="!plan_is_loading" class="container !max-w-[480px] relative z-10">
      <div class="flex flex-col gap-2">
        <plan_component
          :plan="plan"
          :plan_image="plan_image"
          :plan_is_loading="plan_is_loading"
        ></plan_component>
      </div>
    </div>
  </main>
</template>

<script setup>
  // Components
  import { defineAsyncComponent, onMounted, ref } from 'vue'

  const plan_component = defineAsyncComponent(
    () => import('./components/plan.vue')
  )

  const loader_component = defineAsyncComponent(
    () => import('@/components/commons/loader.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Libs
  import { onBeforeMount } from 'vue'
  import { useRouter } from 'vue-router'

  // Helpers
  import is_native_framework from '@/helpers/is_native_framework.js'
  import url from '@/helpers/url.js'

  // Stores
  import UserStore from '@/stores/user'
  import TopNavigationStore from '@/stores/top_navigation'
  import BottomNavigationStore from '@/stores/bottom_navigation'

  // Services
  import Plan from '@/methods/for_plans'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const { is_logged } = User
  const $router = is_native_framework() ? useRouter() : null

  const plan = ref(null)
  const plan_is_loading = ref(true)
  const plan_image = ref(null)

  // Methods
  async function get_plans() {
    plan_is_loading.value = true

    const { success, plans } = await Plan.my_plans()

    if (success) {
      plan.value = await get_plan(plans)

      setTimeout(() => {
        plan_is_loading.value = false
      }, 1500)
    }
  }

  async function get_plan(plans) {
    let plan = plans.find((plan) => plan.starter === 1)

    if (plan?.progress === 100) {
      plan = plans.filter((x) => x.starter !== 1)

      if (plan.length > 0) {
        plan = plan[plan.length - 1]
      } else {
        console.warn('No plan found')
      }
    }

    // Get first route image
    if (plan?.routes?.length > 0) {
      let image = null
      const random_route_index = Math.floor(Math.random() * plan.routes.length)
      const random_route = plan.routes[random_route_index]
      const images = random_route?.route?.images || []

      if (images.length === 0) {
        return
      }

      image = images.find((x) => x.tag === 'route_cover_19')?.path

      if (image) {
        plan_image.value = url({
          path: image,
          with_locale: false
        })
      }
    }

    return plan
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: false
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'continue'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    if (!is_logged) {
      return
    }

    get_plans()
  })
</script>
