// Stores
import AppStore from '@/stores/app'
import UserStore from '@/stores/user.js'

export default function () {
  const App = AppStore()
  const User = UserStore()

  const { is_logged: user_is_logged, user: user_logged } = User

  if (!user_is_logged) {
    return
  }

  window.Echo.channel(`App.Models.User.${user_logged.id}`).listen(
    '.notification',
    (data) => {
      const { locale, level, type, close_after, title, description, content } =
        data

      App.notification({
        title,
        message: description,
        level,
        type,
        close_after
      })
    }
  )
}
