<template>
  <div v-if="loading" class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <div class="skeleton-block title"></div>
      <div class="skeleton-block subtitle"></div>
    </div>

    <div class="skeleton-block route"></div>

    <div class="flex flex-col gap-2">
      <div class="skeleton-block title"></div>
      <div class="skeleton-block subtitle"></div>
    </div>

    <div class="skeleton-block route"></div>
    <div class="skeleton-block route"></div>
    <div class="skeleton-block route"></div>
  </div>

  <alert_component v-if="!loading && rewards.length === 0">
    <template #message>
      {{ gt('We could not find what you are looking for') }}
    </template>
  </alert_component>

  <div class="flex flex-col gap-4" v-if="!loading && rewards.length > 0">
    <title_component>
      <template #title> {{ gt('Rewards') }} </template>
      <template #subtitle>
        {{
          gt(
            'Manage your rewards on the platform, here you will see all available rewards and the ones you already have.'
          )
        }}
      </template>
    </title_component>

    <div class="card dark total_progress">
      <div class="content">
        <div class="icon">
          <i class="fi fi-rr-award leading-none text-2xl"></i>
        </div>
        <div class="flex flex-col">
          <p class="text-2xl font-bold leading-none">{{ progress }} %</p>
          <p class="text-slate-300 font-medium">
            {{ gt('Your progress') }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4">
      <div v-for="reward in rewards" class="flex flex-col gap-2">
        <div class="flex flex-col">
          <p class="text-slate-800 font-medium">
            {{ gt(reward.type.title) }}
          </p>
          <p class="text-slate-700">
            {{ gt(reward.type.description) }}
          </p>
        </div>

        <div
          class="flex flex-col gap-2"
          v-for="category in Object.keys(reward.rewards)"
        >
          <p class="text-slate-800 font-medium">
            {{ gt(category) }}
          </p>
          <div class="flex flex-wrap gap-2">
            <div
              v-for="category_reward in reward.rewards[category]"
              class="badge badge-light badge-reward"
              :class="{
                'badge-dark': category_reward.is_acquired,
                'opacity-50': !category_reward.is_acquired
              }"
              @click="get_reward_info(category_reward)"
            >
              <emoji_component
                :emoji="get_emoji_by_level(category_reward.level)"
                class="w-[18px] aspect-square"
              />

              <span class="text-sm">
                {{ gt(category_reward.level.title) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <title_component>
        <template #title> {{ gt('Benefits') }} </template>
        <template #subtitle>
          {{
            gt(
              'Here you will find all the benefits you can get by acquiring the rewards on the platform.'
            )
          }}
        </template>
      </title_component>

      <ul class="benefits">
        <li class="item">
          <i class="fi fi-rr-check leading-none"></i>
          {{ gt('Customize your username') }}
        </li>
        <li class="item">
          <i class="fi fi-rr-check leading-none"></i>
          {{ gt('Participate in special events') }}
        </li>
        <li class="item">
          <i class="fi fi-rr-check leading-none"></i>
          {{ gt('Access to more and more special modules') }}
        </li>
        <li class="item">
          <i class="fi fi-rr-check leading-none"></i>
          {{ gt('More and more futures benefits') }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent, onBeforeMount, ref, onMounted } from 'vue'
  import { useRouter } from 'vue-router'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import AppStore from '@/stores/app.js'
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Services
  import RewardService from '@/methods/for_rewards.js'

  // Helpers
  import is_native_framework from '@/helpers/is_native_framework.js'
  import get_reading_time_from_string from '@/helpers/get_reading_time_from_string.js'
  import get_emoji_by_level from '@/helpers/get_emoji_by_level.js'

  // Variables
  const App = AppStore()
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const $router = is_native_framework() ? useRouter() : null
  const { is_logged } = User

  const loading = ref(false)
  const rewards = ref([])
  const progress = ref(0)

  // Methods
  function get_reward_info(info) {
    App.notification({
      type: 'modal',
      title: gt(info.criteria),
      message: gt(info.description),
      close_after: get_reading_time_from_string(gt(info.description))
    })
  }

  async function get_rewards() {
    loading.value = true

    const { success, records, user_progress } = await RewardService.get()

    if (success) {
      rewards.value = records
      progress.value = user_progress
    }

    loading.value = false
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('Manage your rewards'),
      go_back: '/journey'
    })

    BottomNavigation.update({
      visible: true,
      active: 'journey'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    if (!is_logged) {
      return
    }

    get_rewards()
  })
</script>
<style lang="scss" scoped>
  @use 'styles/index.scss';
</style>
