<template>
  <title_component :size="'large'" class="mt-[100px]">
    <template #title> {{ gt('Profile') }} </template>
    <template #subtitle>
      {{ gt('Update your profile information.') }}
    </template>
  </title_component>

  <form class="flex flex-col gap-4" v-if="user" @submit.prevent="submit()">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <p class="text-slate-700 font-medium">
            {{ gt('Basic Information') }}
          </p>
          <div class="flex gap-2">
            <div class="flex flex-shrink-0 flex-grow-0 w-[120px]">
              <input_component
                :settings="form.inputs.country_code"
                @update:value="form.inputs.country_code.value = $event"
              />
            </div>

            <div class="flex grow">
              <input_component
                :settings="form.inputs.name"
                @update:value="form.inputs.name.value = $event"
              />
            </div>
          </div>

          <div class="flex gap-2">
            <input_component
              :settings="form.inputs.phone"
              @update:value="form.inputs.phone.value = $event"
            />
          </div>

          <div class="flex gap-2">
            <input_component
              :settings="form.inputs.language"
              @update:value="form.inputs.language.value = $event"
            />
          </div>

          <div class="flex flex-col gap-2">
            <input_component
              :settings="form.inputs.password"
              @update:value="form.inputs.password.value = $event"
            />
          </div>
        </div>

        <button_component
          :classes="['btn', 'btn-dark', 'w-fit']"
          :loading="form.is_loading"
          :submittable="true"
        >
          <i class="fi fi-rr-check"></i>
          {{ gt('Save') }}
        </button_component>
      </div>
    </div>
  </form>

  <alert_component v-if="!loading && !user">
    <template #title> {{ gt('Hmmm...') }} </template>
    <template #message>
      {{ gt('We could not find your information') }}
    </template>
  </alert_component>

  <alert_component v-if="loading && !user" :loader="true">
    <template #title> {{ gt('Wait some seconds...') }} </template>
    <template #message>
      {{ gt('We are finding your information.') }}
    </template>
  </alert_component>
</template>
<script setup>
  // JSONS
  import countries from './assets/countries.json'

  // Components
  import {
    defineAsyncComponent,
    ref,
    onBeforeMount,
    onMounted,
    watch
  } from 'vue'
  import { useRouter } from 'vue-router'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  // Services
  import UserService from '@/methods/for_users'

  // Helpers
  import get_form_state from '@/helpers/get_form_state'
  import redirect_if_necessary from '@/helpers/redirect_if_necessary'
  import is_native_framework from '@/helpers/is_native_framework.js'
  import is_vue_running_on from '@/helpers/is_vue_running_on.js'

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t, locale } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const $router = is_native_framework() ? useRouter() : null
  const { is_logged, user: user_logged } = User
  const loading = ref(true)
  const user = ref(null)
  const form = get_form_state('profile', [
    {
      label: gt('Name'),
      name: 'name',
      type: 'text'
    },
    {
      label: gt('New password'),
      name: 'password',
      type: 'password',
      description: gt("Leave it blank if you don't want to change it.")
    },
    {
      label: gt('Country'),
      name: 'country_code',
      type: 'select',
      options: countries.map((country) => ({
        id: country.country_code,
        value: `${country.flag} ${country.name} (${country.phone_code})`
      }))
    },
    {
      label: gt('Phone'),
      name: 'phone',
      type: 'text',
      description: gt('Please add your country code as well e.g. +11234567890')
    },
    {
      label: gt('What is your preferred language'),
      name: 'language',
      type: 'select',
      options: [
        { id: 'en', value: gt('English') },
        { id: 'es', value: gt('Spanish') }
      ],
      default: 'en',
      value: 'en',
      description: gt(
        'Be careful when changing languages because you will have new routes assigned to you, and you will lose your previous progress.'
      )
    }
  ])

  // Watchers
  watch(
    () => form.inputs.country_code.value,
    () => {
      if (
        !form.inputs.phone.value ||
        (form.inputs.phone.value && form.inputs.phone.value.length === 0)
      ) {
        const country_phone_code = countries.find(
          (country) => country.country_code === form.inputs.country_code.value
        )?.phone_code

        form.inputs.phone.value = country_phone_code
        form.inputs.phone.default = country_phone_code
      }
    }
  )

  // Methods
  async function submit() {
    const { success, user } = await UserService.update({
      $form: form
    })

    if (success) {
      await get_user_information()

      User.update({
        name: user.name,
        language: user.language
      })

      // If the user changed the language, we need to reload the page
      if (user.language !== user_logged.language) {
        locale.value = user.language

        if ($router) {
          await $router.push('/performance')
          return
        }

        if (is_vue_running_on() === 'memeonlyme.com') {
          window.location.reload()
        }
      }

      setTimeout(() => {
        redirect_if_necessary({
          path: null,
          $router
        })
      }, 3000)
    }
  }

  async function get_user_information() {
    if (!is_logged) {
      return
    }

    loading.value = true

    const { success, records } = await UserService.get({
      specific: true,
      with_properties: ['phone', 'avatar', 'language', 'country'],
      filters: {
        id: user_logged.id
      }
    })

    if (success) {
      user.value = records.length > 0 ? records[0] : null

      if (user.value) {
        Object.values(['name', 'phone', 'language', 'country_code']).forEach(
          (key) => {
            if (!form.inputs[key]) {
              return
            }

            if (key === 'country_code') {
              form.inputs[key].value = user.value.country
              form.inputs[key].default = user.value.country
              return
            }

            form.inputs[key].value = user.value[key]
            form.inputs[key].default = user.value[key]
          }
        )
      }
    }

    loading.value = false
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('My Profile'),
      go_back: '/journey'
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'continue'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })

  onMounted(() => {
    if (!is_logged) {
      return
    }

    get_user_information()
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
