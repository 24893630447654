// Services
import CommonService from '@/methods/for_common_actions.js'

export default async ({ event = null, append = {} }) => {
  let session_id = localStorage.getItem('session_id')

  if (!session_id) {
    console.warn('Session ID is required')
  }

  if (!event) {
    console.warn('Event is required')
  }

  await CommonService.track({
    append: {
      session_id,
      event,
      ...append
    }
  })
}
