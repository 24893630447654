export default function (level) {
  switch (level.id) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return 'white_heart'

    case 8:
      return 'green_heart'

    case 9:
      return 'pink_heart'

    case 10:
      return 'fire'
  }
}
