// Services
import SessionService from '@/methods/for_sessions.js'

// Enums
import ENV from '@/../env.json'

// Variables
const UTMS = ENV.utm_variables

export default async () => {
  const uuid_regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const utms = {}
  let current_session_id = localStorage.getItem('session_id')

  if (!uuid_regex.test(current_session_id)) {
    localStorage.removeItem('session_id')
    current_session_id = null
  }

  for (const utm of UTMS) {
    utms[utm] = localStorage.getItem(utm)
  }

  const { session_id, new_session } = await SessionService.get_session({
    pathname: window.location.pathname,
    session_id: current_session_id,
    ...utms
  })

  localStorage.setItem('session_id', session_id)

  if (new_session) {
    window.dispatchEvent(new Event('new_session'))
  }
}
