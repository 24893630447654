// Helpers
import is_vue_running_on from '@/helpers/is_vue_running_on.js'
import get_client_suggested_locale from '@/helpers/get_client_suggested_locale.js'

export default function () {
  if (is_vue_running_on() === 'app') {
    return localStorage.getItem('locale') || get_client_suggested_locale()
  } else {
    const supported_locales = ['en']
    const url_first_segment = window.location.pathname.split('/')[1]

    if (supported_locales.includes(url_first_segment)) {
      return url_first_segment
    }

    return 'es'
  }
}
