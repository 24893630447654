<template>
  <title_component :size="'large'">
    <template #title> {{ gt('Appointments') }} </template>
    <template #subtitle>
      {{ gt('View and manage your appointments') }}
    </template>
  </title_component>

  <div class="tabs">
    <span
      :class="['tab', { active: tab === 'next_to' }]"
      @click="tab = 'next_to'"
    >
      {{ gt('Ongoing') }}
    </span>

    <span
      :class="['tab', { active: tab === 'make_an_appointment' }]"
      @click="tab = 'make_an_appointment'"
    >
      {{ gt('Make one') }}
    </span>

    <span
      :class="['tab', { active: tab === 'appointments' }]"
      @click="tab = 'appointments'"
    >
      {{ gt('History') }}
    </span>
  </div>

  <make_an_appointment_component
    v-if="tab === 'make_an_appointment'"
    :change_tab_to="change_tab_to"
  />

  <appointments_components
    :title="gt('Ongoing')"
    :subtitle="gt(`Let's check your next appointments`)"
    :statuses="[STATUSES.PENDING, STATUSES.CONFIRMED]"
    v-if="tab === 'next_to'"
  />

  <appointments_components
    :title="gt('History')"
    :subtitle="gt(`Let's check your appointments history`)"
    :statuses="[
      STATUSES.CANCELLED,
      STATUSES.ATTENDED,
      STATUSES.FINISHED_BY_OVERTIME
    ]"
    v-if="tab === 'appointments'"
  />
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Components
  import { defineAsyncComponent, ref, onBeforeMount, watch } from 'vue'
  import { useRouter } from 'vue-router'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const make_an_appointment_component = defineAsyncComponent(
    () => import('./tabs/make_an_appointment/index.vue')
  )

  const appointments_components = defineAsyncComponent(
    () => import('./tabs/appointments/index.vue')
  )

  // Translation needs
  import { useI18n } from 'vue-i18n'
  import get_translation from '@/helpers/get_translation'
  const { t } = useI18n()
  const gt = (text, args) => get_translation(t, text, args)

  // Helpers
  import is_native_framework from '@/helpers/is_native_framework.js'
  import get_query_param from '@/helpers/get_query_param.js'

  // Stores
  import UserStore from '@/stores/user'
  import BottomNavigationStore from '@/stores/bottom_navigation'
  import TopNavigationStore from '@/stores/top_navigation'

  // Variables
  const User = UserStore()
  const BottomNavigation = BottomNavigationStore()
  const TopNavigation = TopNavigationStore()

  const $router = is_native_framework() ? useRouter() : null
  const { is_logged } = User
  const tab = ref(get_query_param('tab', 'appointments'))

  const STATUSES = ENV.enums.AppointmentStatuses

  // Watchers
  watch(tab, (new_tab) => {
    const current_url = new URL(window.location.href)
    current_url.searchParams.set('tab', new_tab)
    window.history.pushState({}, '', current_url)
  })

  // Methods
  function change_tab_to(new_tab) {
    tab.value = new_tab
  }

  function redirect_if_is_not_logged() {
    if (is_logged) {
      return
    }

    if (!$router) {
      window.location.href = '/login'
    } else {
      $router.push('/login')
    }
  }

  function set_title_and_navigation() {
    TopNavigation.update({
      loading: false,
      visible: true,
      title: gt('Appointments'),
      go_back: '/journey'
    })

    BottomNavigation.update({
      visible: true,
      active_menu: 'continue'
    })
  }

  // Lifecycle
  onBeforeMount(() => {
    redirect_if_is_not_logged()
    set_title_and_navigation()
  })
</script>
<style lang="scss" scoped>
  @use './styles/index.scss';
</style>
