// Helpers
import url from '@/helpers/url'
import get_query_param from '@/helpers/get_query_param.js'

export default async function ({ path = null, $router = null }) {
  const param_redirect = get_query_param('redirect')
  const param_redirect_to = get_query_param('redirect_to')
  const redirect_to = param_redirect || param_redirect_to

  if (redirect_to) {
    if ($router) {
      $router.push(redirect_to)
      return
    }

    window.location.href = url({
      path: redirect_to
    })
    return
  }

  if (path) {
    if ($router) {
      $router.push(path)
      return
    }

    window.location.href = url({
      path
    })
  }
}
