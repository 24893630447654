// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  get = async (body = {}) => {
    return await this.execute({
      name: 'rewards.get',

      method: 'get',
      target: '/v1/rewards',
      body
    })
  }

  get_unclaimed = async (body = {}) => {
    return await this.execute({
      name: 'rewards.get_unclaimed',

      method: 'get',
      target: '/v1/rewards/unclaimed',
      body
    })
  }

  claim = async (body = {}) => {
    return await this.execute({
      name: 'rewards.claim',

      method: 'post',
      target: '/v1/rewards/claim',
      body
    })
  }
}
