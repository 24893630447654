import { createApp } from 'vue'

export default function (container_id, marker, component) {
  const container = document.getElementById(container_id)

  if (!container) {
    return
  }

  container.querySelectorAll('p').forEach((paragraph) => {
    if (paragraph.innerHTML.includes(marker)) {
      const vue_container = document.createElement('div')
      vue_container.setAttribute(
        'id',
        `vue-${marker}-${Math.random().toString(36).substr(2, 9)}`
      )

      paragraph.innerHTML = paragraph.innerHTML.replace(
        marker,
        vue_container.outerHTML
      )

      const new_container = document.getElementById(vue_container.id)
      if (new_container) {
        createApp(component).mount(new_container)
      }
    }
  })
}
