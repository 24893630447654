// Libs
import sha256 from 'crypto-js/sha256'

// Helpers
import Request from '@/helpers/request'

export default function (t, text, args = {}) {
  if (typeof t !== 'function') {
    return '{*Error}'
  }

  const token = sha256(text).toString()
  const translation = t(token, args)

  if (translation === token) {
    new Request().post('/v1/translation', { text })
    return text
  }

  return translation
}
